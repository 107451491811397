import React from 'react';
import ArrowLeft from "./../assets/images/al.png";
import editIcon from "./../assets/images/icon/edit.png";
import ArrowRight from "./../assets/images/ar.png";
import HomeService from '../services/HomeService';
import AuthenticationService from "../services/AuthenticationService";
import { GetLocalizationString, HidePreloader, ShowNotifications, SetDefaultLanguage, ShowPreloader } from './../Helpers/CustomHelper';
import { Link } from 'react-router-dom';
import dft_category_icon from './../assets/images/icon/dft_category_icon.png';

class MyProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_detail: {},
            products: [],
            isLoaded: false,
            showModal: false,
            currentProduct: null,
            error: null,
            user_data: {},
            page: 1,
            per_page: process.env.REACT_APP_PER_PAGE,
            product_counts: 0
        };
        this.handleScroll = this.debounce(this.handleScroll.bind(this), 200);  // Debounce the scroll handler
    }

    componentDidMount() {
        SetDefaultLanguage();
        ShowPreloader();

        // Fetch user profile data
        this.getUserProfile();
        window.addEventListener("scroll", this.handleScroll); /** attaching scroll event listener */      
        

    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    getProductsList = () => {
        ShowPreloader();
        const { user_data } = this.state;
        if(user_data.service_category.category_type != 3){
            this.props.history.push('/home');
        }
        let params = {
            level2_service_category_id: user_data.level2_service_category_id,
            level: 1,
            seller:1,
            "per_page": this.state.per_page,
            "page": this.state.page,
        };
        HomeService.getProductsList(params)
            .then((res) => {
                console.log(res,"res1");
                if ((global.successStatus).includes(res.status)) {
                    HidePreloader();
                    // Loop to add quantity 1 to all products
                    // const updatedProducts = res.data.products.map(product => ({
                    //     ...product,
                    //     quantity: 0                        
                    // }));
                    this.setState({
                        products: [...this.state.products,...res.data.products],
                        product_counts: res.data.product_counts,
                        isLoaded: true,
                        loading: false
                    });
                } else {
                    HidePreloader();
                    console.log(res);
                    ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
                    this.setState({ loading: false });
                }
            }).catch((errors) => {
                HidePreloader();
                console.error(errors);
                ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
                this.setState({ loading: false });
            });
    }

    getUserProfile(){
        AuthenticationService.getProfile()
        .then((res)=> {
            if((global.successStatus).includes(res.status)) {
                SetDefaultLanguage(res.data.selected_language)                
                this.setState({
                    user_data: res.data,
                    default_language: res.data.default_language,
                    badge_count:res.data.badge_count,                    
                })
                localStorage.setItem("userData", res.data ? JSON.stringify(res.data) : {})
                // Fetch products for the current user's mall
                this.getProductsList();
            }else{
                console.log(res) 
            }
        }).catch((errors)=>{
            console.error(errors)
        })
      }

    openModal = (product) => {
        this.setState({ showModal: true, currentProduct: product });
        alert("Edit Product");
    };

    closeModal = () => {
        this.setState({ showModal: false, currentProduct: null });
    };

    handleEdit = (e) => {
        e.preventDefault();
        this.closeModal();
    };

    debounce(func, delay) {
        let debounceTimer;
        return function (...args) {
            const context = this;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    }

    handleScroll() {
        const userScrollHeight = window.innerHeight + window.scrollY;
        const windowBottomHeight = document.documentElement.offsetHeight;
        const totalPages = Math.ceil(this.state.product_counts / this.state.per_page);

        if (userScrollHeight >= windowBottomHeight && !this.state.loading && this.state.page < totalPages) {
            this.setState(
                prevState => ({
                    page: prevState.page + 1,
                    loading: true,
                }),
                () => this.getProductsList(this.state.page)
            );
        }
    }
    render() {
        const { products, isLoaded } = this.state;

        return (
            isLoaded ? (
                <>
                    <header>
                        <div className="container">
                            <div className="row">
                                <div className="col-2">
                                    <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
                                </div>
                                <div className="col-8">
                                    <h1 className="head_heading">{GetLocalizationString('my_product')}</h1>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 product-list mb-3">
                        {
                            products.length <= 0 ? (
                                <div className="success_box no_cnt_data">
                                    <h5 className="app_done">{GetLocalizationString('no_product')}</h5>
                                </div>
                            ) : (
                                products.map((product) => (
                                    <div className="card mb-3" key={product.product_id} style={{ borderRadius: '15px', padding: '15px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                                        <div className="row g-0 align-items-center">
                                            {/* Product Image */}
                                            <div className="col-md-2 text-center">
                                                <img 
                                                    src={product.product_image || dft_category_icon}
                                                    alt={product.product_name} 
                                                    className="img-fluid img-listing"                                                     
                                                />
                                            </div>
                                            
                                            {/* Product Details */}
                                            <div className="col-md-8" style={{ borderLeft: "1px solid #D1D1D1", paddingLeft: "10px" }}>
                                                <div className="card-body">
                                                    <h2 className="card-title m-0" style={{color:"#396EA0"}}>{product.product_name}</h2>
                                                    <p className="card-text m-0 text-muted">Category: {product.service_category_title}</p>
                                                    <p className="card-text m-0"><strong>₹{product.price}</strong> (Per {product.unit_type})</p>
                                                    <div className="row align-items-center mt-2">
                                                        <div className="col">
                                                            <p className="card-text m-0">Sold: {product.sold_qty || 0}</p>
                                                        </div>
                                                        <div className="col">
                                                            <p className="card-text m-0">Available Qty: {product.available_qty || 0}</p>
                                                        </div>
                                                        <div className="col text-right">
                                                            {product.available_qty === 0 ? (
                                                                <span className="badge bg-danger text-white">Sold Out</span>
                                                            ) : product.available_qty <= product.limit_alert ? (
                                                                <span className="badge bg-danger text-white">Only {product.available_qty} left</span>
                                                            ) : null}                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Edit Button */}
                                            <div className="col-md-2 text-end">
                                                <Link className="edit-button" 
                                                    to={{
                                                        pathname: '/manage-product',
                                                        user_data: this.state.user_data,
                                                        product: product,
                                                        requestFor:"update"
                                                    }}
                                                >
                                                    <img className='float-right' src={editIcon} alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )
                        }
                    </div>                                
                </div>
                {/* <div className="Foot_btn_grievance"> */}
                <Link className="btn btn-theme mb-5" 
                    to={{
                        pathname: '/manage-product',
                        user_data: this.state.user_data,
                        requestFor:"add"
                        }}
                    >
                        {GetLocalizationString('add_new')} <img src={ArrowRight} alt="" />
                    </Link>
                    {/* </div> */}
            </div>
        </section>

                    <section className='d-none'>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 product-list">
                                    {
                                        products.length <= 0 ? (
                                            <div className="success_box no_cnt_data">
                                                <h5 className="app_done">{GetLocalizationString('no_product')}</h5>
                                            </div>
                                        ) : (
                                            products.map((product) => (
                                                <div className="product-card" key={product.product_id}>
                                                    <div className="product-image">
                                                        {product.product_image ? (
                                                            <img src={product.product_image} alt={product.product_name} />
                                                        ) : (
                                                            <span className="placeholder">🍏</span>
                                                        )}
                                                    </div>
                                                    {/* Product Details */}
                                                    <div className="product-details flex-grow-1 mx-3" style={{ borderLeft: "1px solid #D1D1D1", paddingLeft: "10px" }}>
                                                        <h1 className="m-0">{product.product_name}</h1>
                                                        <p className="m-0"><span className='text-bold'>₹{product.price}</span> (Per {product.unit_type})</p>
                                                        <div className="col-4">Sold: {product.sold_qty || 0} {product.unit_type}</div>
                                                        <div className="col-4">Available Qty: {product.available_qty || 0} {product.unit_type}</div>
                                                        <div className="col-4">{product.available_qty < product.limit_alert && <span className="warning">Only {product.available_qty} left</span>}</div>
                                                    </div>
                                                    
                                                    <Link className="edit-button" 
                                                    to={{
                                                        pathname: '/manage-product',
                                                        user_data: this.state.user_data,
                                                        product: product
                                                    }}
                                                    >✏️</Link>
                                                </div>
                                            ))
                                        )
                                    }
                                </div>                                
                            </div>
                            <div className="Foot_btn_grievance">
                            <Link className="btn btn-theme mb-5 Foot_btn_grievance" 
                            to={{
                                pathname: '/manage-product',
                                user_data: this.state.user_data                                
                              }}
                            >
                                {GetLocalizationString('add_new')} <img src={ArrowRight} alt="" />
                            </Link>
                            </div>
                        </div>
                    </section>
                </>
            ) : null
        );
    }
}

export default MyProduct;
