import React, { Component, Suspense } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import SendOTP from "./authentication/SendOTP"
import VerifyOTP from "./authentication/VerifyOTP"
import Logout from "./authentication/Logout"
import Home from "./Home"
import Register from "./authentication/Register"
import Page403 from "./authentication/Page403"
import UpdateProfile from "./authentication/UpdateProfile"
import SelectCategories from "./../components/common/SelectCategories"
import SelectSpecialization from "./common/SelectSpecialization"
import SelectSubCategories from "./common/SelectSubCategories"
import ProvidersList from "./ProvidersList"
import MyProfile from "./authentication/MyProfile"
import ProviderDetail from "./ProviderDetail"
import ProviderProfile from "./ProviderProfile"
import ProviderBookings from "./ProviderBookings"
import MyProduct from "./MyProduct"
import PatientBookings from "./PatientBookings"
import SendProviderReview from "./SendProviderReview"
/*import SendGrievanceReport from "./SendGrievanceReport"*/
import GrievanceCategoryDetail from "./GrievanceCategoryDetail"
import UpdatePayment from "./authentication/UpdatePayment"
import UpdateSellerPayment from "./authentication/UpdateSellerPayment"
import UpdateDoctorSlots from "./authentication/UpdateDoctorSlots"
import BookingDetail from "./BookingDetail"
import ResidentBookings from "./ResidentBookings"
import AppointmentSuccess from "./AppointmentSuccess" 
import ProvidersListMap from "./ProviderListMap"
import MyReviews from "./MyReviews"
import ReviewDetail from "./ReviewDetail"
import UpdateAddress from "./authentication/UpdateAddress"
import GrievanceReportSent from "./GrievanceReportSent"
import RescheduleAppointment from "./RescheduleAppointment"
import ProviderReviews from "./ProviderReviews"
import RegisterSuccess from "./authentication/RegisterSuccess"
import ReviewSent from "./ReviewSent"
import MyNotifications from "./MyNotifications"
import TermsConditions from "./TermsConditions"
import AppTermsConditions from "./AppTermsConditions"
import SplashScreen from "./SplashScreen"
import SendSuggestion from "./SendSuggestion"
import SuggestionSent from "./SuggestionSent"
import ProductList from "./ProductList"
import Products from "./Products"
// import SearchProducts from "./SearchProducts"
import Checkout from "./Checkout"
import MyOrder from "./MyOrder"
import OrderConfirmation from "./OrderConfirmation"
import OrderDetails from "./OrderDetails"
import MyReceivedOrder from "./MyReceivedOrder"
import MyCart from "./MyCart"
import ManageProduct from "./ManageProduct"
import MyCategory from "./MyCategory"
import ManageCategory from "./ManageCategory"
import Feedback from "./Feedback"


export class AppRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      /** Add routes name to bypass login*/
      byPassLoginRoutes: [
        "/",
        "/login",
        "/splash",
        "/register",
        "/select-category",
        "/select-sub-category",
        "/select-specialization",
        "/home",
        "/verify-otp",
        "/logout",
        "/get-providers-list",
        "/get-providers-map-view",
        "/provider-detail",
        "/send-provider-review",
        "/send-grievance-report",
        "/grievance-report-sent",
        "/provider-reviews",
        "/register-success",
        "/review-sent",
        "/terms-conditions",
        "/suggestions",
        "/web-terms-conditions",
        '/suggestion-sent',
        '/contact-us',
      ],
    };
  }

  render() {
    // Check headerToken exist or not
    let pathname = window.location.pathname;
    let items = pathname.split("/").slice(-2);
    let routeName = items[0] ? "/" + items[0] : pathname;
    if (
      !global.headerToken &&
      !this.state.byPassLoginRoutes.includes(routeName)
    ) {
      return <Redirect to="/logout" />;
    }

    return (
      <Suspense fallback="">
        <Switch>
          <Route exact path="/">
            <Redirect to="/splash"></Redirect>
          </Route>
          <Route exact path='/web-terms-conditions' component={TermsConditions} />
          <Route exact path='/terms-conditions/:slug' component={AppTermsConditions} />
          <Route exact path="/login" component={SendOTP} />
          {/* splash screen */}
          <Route exact path="/splash" component={SplashScreen} />
          {/* splash screen */}
          <Route exact path="/register" component={Register} />
          <Route exact path="/register-success/:slug" component={RegisterSuccess} />
          <Route exact path="/select-category" component={SelectCategories} />
          <Route exact path="/select-sub-category" component={SelectSubCategories} />
          <Route exact path="/select-specialization" component={SelectSpecialization} />
          <Route exact path="/my-profile" component={MyProfile} />
          <Route exact path="/my-notifications" component={MyNotifications} />
          <Route exact path="/update-profile" component={UpdateProfile} />
          <Route exact path="/update-address-location" component={UpdateAddress} />
          <Route exact path="/get-providers-list/:slug" component={ProvidersList} />
          <Route exact path="/get-providers-map-view/:slug" component={ProvidersListMap} />
          <Route exact path="/provider-detail/:slug" component={ProviderDetail} />
          <Route exact path="/reschedule-appointment/:slug/:appointment_id" component={RescheduleAppointment} />
          <Route exact path="/provider-profile" component={ProviderProfile} />
          <Route exact path="/my-reviews/:slug" component={MyReviews} />
          <Route exact path="/review-sent" component={ReviewSent} />
          <Route exact path="/provider-reviews/:slug" component={ProviderReviews} />
          <Route exact path="/review-detail/provider" component={ReviewDetail} />
          <Route exact path="/provider-bookings/:slug" component={ProviderBookings} />
          <Route exact path="/my-bookings/:slug" component={ResidentBookings} />
          <Route exact path="/booking-detail/:slug" component={BookingDetail} />
          <Route exact path="/appointment-booked" component={AppointmentSuccess} />
          <Route exact path="/patient-bookings/:slug" component={PatientBookings} />
          <Route exact path="/send-provider-review/:slug" component={SendProviderReview} />
          <Route exact path="/send-grievance-report/:slug" component={GrievanceCategoryDetail} />
          <Route exact path="/grievance-report-sent" component={GrievanceReportSent} />
          <Route exact path="/update-provider-payment/:slug" component={UpdatePayment} />
          <Route exact path="/update-seller-payment/:slug" component={UpdateSellerPayment} />
          <Route exact path="/update-provider-slots/:slug" component={UpdateDoctorSlots} />
          <Route exact path="/verify-otp" component={VerifyOTP} />
          <Route exact path="/suggestions" component={SendSuggestion} />
          <Route exact path="/suggestion-sent" component={SuggestionSent} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/page-403" component={Page403} />
          <Route exact path="/home" component={Home} />          

          {/* Shopping module routes */}
          <Route exact path="/my-product/:slug" component={MyProduct} />
          <Route exact path="/my-orders/:slug" component={MyOrder} />
          <Route exact path="/product-list/:slug" component={ProductList} />
          <Route exact path="/products/:slug" component={Products} />
          <Route exact path="/search-products" component={Products} />
          {/* <Route exact path="/search-products" component={SearchProducts} /> */}
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/order-confirmation" component={OrderConfirmation} />
          <Route exact path="/order-detail/:slug" component={OrderDetails} />
          <Route exact path="/my-received-order/:slug" component={MyReceivedOrder} />
          <Route exact path="/my-cart" component={MyCart} />
          <Route exact path="/manage-product" component={ManageProduct} />
          <Route exact path="/my-categories/:slug" component={MyCategory} />
          <Route exact path="/manage-category" component={ManageCategory} />
          <Route exact path="/contact-us" component={Feedback} />
        
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
