////////////////////////////////////////////////////////////
//     							                                      //
//  Program: App.jsx                                      //
//  Application: Components                               //
//  Option: App component                                 //
//  Developer: CV  						                            //
//  Date: 2021-03-01                                      //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { withRouter } from "react-router-dom";
import "./App.scss"
import AppRoutes from "./AppRoutes";
import Header from "./common/Header";
import OnKeyPressEvent from "./common/OnKeyPressEvent";
import Moment from "moment";

// Set data as a global for common use
global.spinnerLoader = (
  <div className="spinnerLoader">
    <div className="toploader">
    </div>
  </div>
);
global.loader = (
  <span>
    <span
      className="spinner-border spinner-border-sm mg-r-5"
      role="status"
      aria-hidden="true"
    ></span>
    Loading...
  </span>
);
// To check user's access permissions
global.userPermissions = new OnKeyPressEvent();
// Use for datatable
global.page_length_menu = [10, 20, 50, 100];
global.page_size = process.env.REACT_APP_PAGE_SIZE
  ? parseInt(process.env.REACT_APP_PAGE_SIZE)
  : 10;
global.show_filter =
  process.env.REACT_APP_SHOW_FILTER === "true" ? true : false;
global.show_pagination_info =
  process.env.REACT_APP_SHOW_PAGINATION_INFO === "true" ? true : false;
global.show_pagination =
  process.env.REACT_APP_SHOW_PAGINATION === "true" ? true : false;
global.pagination = process.env.REACT_APP_PAGINATION
  ? process.env.REACT_APP_PAGINATION
  : "basic";
global.excel_button =
  process.env.REACT_APP_SHOW_EXCEL_BUTTON === "true" ? true : false;
global.print_button =
  process.env.REACT_APP_SHOW_PRINT_BUTTON === "true" ? true : false;
global.filter_time = process.env.REACT_APP_FILTER_TIME
  ? parseInt(process.env.REACT_APP_FILTER_TIME)
  : 0;
// User for datatble to display all records without pagination
global.all_page_length_menu = [];
global.max_page_size = process.env.REACT_APP_MAX_PAGE_SIZE
  ? parseInt(process.env.REACT_APP_MAX_PAGE_SIZE)
  : 1000000;
global.hide_filter =
  process.env.REACT_APP_HIDE_FILTER === "true" ? true : false;
global.hide_pagination_info =
  process.env.REACT_APP_HIDE_PAGINATION_INFO === "true" ? true : false;
global.hide_pagination =
  process.env.REACT_APP_HIDE_PAGINATION === "true" ? true : false;
// Use for countdown timer
global.countdown_time = process.env.REACT_APP_TIMER_VALUE
  ? parseFloat(process.env.REACT_APP_TIMER_VALUE)
  : 0;
// Use for page redirection after save/update data
global.redirect_time = process.env.REACT_APP_AUTO_REDIRECT_TIME
  ? parseFloat(process.env.REACT_APP_AUTO_REDIRECT_TIME)
  : 0;
// Use for auto alert popup close time after display
global.alert_close_time = process.env.REACT_APP_ALERT_CLOSE_TIME
  ? parseFloat(process.env.REACT_APP_ALERT_CLOSE_TIME)
  : 0;
// Use for authentication
global.headerToken = localStorage.getItem("headerToken")
  ? localStorage.getItem("headerToken")
  : "";
global.userData = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData"))
  : {};
// Data from setting apis
global.currentPeriod = localStorage.getItem("currentPeriod")
  ? localStorage.getItem("currentPeriod")
  : "";
global.finYearEnd = localStorage.getItem("finYearEnd")
  ? localStorage.getItem("finYearEnd")
  : "";
global.lastUpdate = localStorage.getItem("lastUpdate")
  ? localStorage.getItem("lastUpdate")
  : "";
global.prevPeriodDate = localStorage.getItem("prevPeriodDate")
  ? localStorage.getItem("prevPeriodDate")
  : "";
global.currentPeriodDate = localStorage.getItem("currentPeriodDate")
  ? localStorage.getItem("currentPeriodDate")
  : "";
global.permissions = localStorage.getItem("permissions")
  ? localStorage.getItem("permissions")
  : "";
global.yearDropdownItemNumber = 3;
global.successStatus = [200, 201, 202];
global.showOpen = false;
global.current_year = Moment(new Date()).format("YYYY");
// Use for prevent values on keypress
global.onKeyPressEvent = new OnKeyPressEvent();
//day list

global.selectRoleList = [
  { value: "1", name: "Company User" },
  { value: "2", name: "Customer Admin" },
  { value: "3", name: "Property Admin" },
  { value: "4", name: "Auditor" },
];

// For by default Show/Hide Custom Filter
global.showFilter = 0;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileImage: "",
    };
    this.updateProfile = this.updateProfile.bind(this);
  }

  componentDidMount() {
  //   if(global.headerToken == '') {
  //     console.log('Token not found');
  //     this.props.history.push({
  //         pathname: '/logout',
  //     });
  // }
    this.onRouteChanged();
  }

  updateProfile(imagePath) {
    this.setState({ profileImage: imagePath });
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }
  
  render() {
    let headerComponent = !this.state.isFullPageLayout ? <Header /> : "";
    
    return (
      <div className="d-flex">
        <div className="az-sidebar">{headerComponent}</div>
        <div className="w-100">
          <div className="az-content-wrapper">
            
            <div className="d-flex az-header-top justify-content-between">
              <div className="az-header-left">
                <a
                  href="#/"
                  id="azMenuShow"
                  onClick={(event) => this.toggleHeaderMenu(event)}
                  className="az-header-menu-icon"
                >
                  <span></span>
                </a>
              </div>
            </div>
      
            <AppRoutes updateProfileImage={this.updateProfile} />
          </div>
          <div className="az-navbar-backdrop" onClick={event => this.toggleHeaderMenu(event)}></div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/login",
      "/2fa-signin",
      "/logout",
      "/page-403",
      "/forgot-password",
      "/2fa-forgot-password",
      "/reset-password",
      "/account-activation",
      "/view-report",
      "/view-reports",
      "/clear-cache",
      "/landing",
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname.includes(fullPageLayoutRoutes[i])) {
        this.setState({
          isFullPageLayout: true,
        });
        document.querySelector(".az-sidebar").classList.add("d-none");
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document.querySelector(".az-sidebar").classList.remove("d-none");
      }
    }
  }
}

export default withRouter(App);
