////////////////////////////////////////////////////////////
//     							                          //
//  Program: HomeService.js                               //
//  Application: Services                                 //
//  Option: Used for authentication components            //
//  Developer:   						                  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from './ApiService'

class HomeService {

    GetAllCategoriesList(data) {
        let endPoint = 'get-service-categories';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetProvidersList(data) {
        let endPoint = 'get-all-providers';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetProviderDetail(data) {
        let endPoint = 'get-provider-detail';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetEmergencyContacts(data) {
        let endPoint = 'get-emergency-contacts';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetGrievanceContacts(data) {
        let endPoint = 'get-grievance-contacts';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetAllBookings(data) {
        let endPoint = 'get-all-bookings';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetAllReviews(data) {
        let endPoint = 'get-my-reviews';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetProviderReviews(data) {
        let endPoint = 'get-all-reviews';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    SaveProviderSlot(data) {
        let endPoint = 'add-provider-slot';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    SendProviderReview(data) {
        let endPoint = 'send-review';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    BookAppointment(data) {
        let endPoint = 'book-appointment';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    SendGrievanceReport(data) {
        let endPoint = 'send-grievance-report';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    SendSuggestions(data) {
        let endPoint = 'send-suggestion';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    CancelAppointment(data) {
        let endPoint = 'change-appointment-status';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    UpdateGPSLocation(data) {
        let endPoint = 'update-gps-location';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    Get3DModals(data) {
        let endPoint = 'get-3d-models';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetAllNotifications(data) {
        let endPoint = 'notification-list?page='+data.page+'&per_page='+data.per_page;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.getCall(inputData);
        return response;
    }
    ReadNotifications(data) {
        let endPoint = 'notification-read?type='+data.type+'&slug='+data.slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.getCall(inputData);
        return response;
    }

    PlaceOrder(data) { 
        let endPoint = 'manage-order'; 
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    GetMyOrders(data) {
        let endPoint = 'get-orders';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        console.log(response,"response");
        return response;
    }

    GetMyReceivedOrders(data) {
        let endPoint = 'get-customer-orders';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        console.log(response,"response");
        return response;
    }

    GetOrderDetails(data,slug) {
        let endPoint = 'get-order-detail/'+slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        console.log(response,"response");
        return response;
    }
    UpdateOrderStatus(data,slug) {
        let endPoint = 'update-order-status/'+slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        console.log(response,"response");
        return response;
    }
    getProductsList(data) {
        let endPoint = 'get-products';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        console.log(response,"response");
        return response;
    }

    addToCart(data) {
        let endPoint = 'manage-cart';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        console.log(response,"response");
        return response;
    }
    getCartItems(data) {
        let endPoint = 'get-cart-items';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        console.log(response,"response");
        return response;
    }
    getProducts(data) {
        let endPoint = 'get-products';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        console.log(response,"response");
        return response;
    }
    getCategories(data) {
        let endPoint = 'get-categories';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        console.log(response,"response");
        return response;
    }
    getUnitType(data) {
        let endPoint = 'get-unit-type';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.getCall(inputData);
        console.log(response,"response");
        return response;
    }

    ManageProduct(data) {
        let endPoint = 'manage-product';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        console.log(response,"response");
        return response;
    }
    SaveCategory(data) {
        let endPoint = 'save-category';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        console.log(response,"response");
        return response;
    }
    UpdateCategory(data,slug) {
        let endPoint = 'edit-category/'+slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        console.log(response,"response");
        return response;
    }

    DeleteCartItems(slug) {
        let endPoint = 'delete-cart-items/'+slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.deleteCall(inputData);
        console.log(response,"response");
        return response;
    }
    DeleteCart(slug) {
        let endPoint = 'delete-cart/'+slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.deleteCall(inputData);
        console.log(response,"response");
        return response;
    }

    UploadPaymentReceipt(data) {
        let endPoint = 'upload-order-payment-receipt';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        console.log(response,"response");
        return response;
    }

    PlaceFeedback(data) {
        let endPoint = 'place-feedback';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
        }
        let response = ApiService.postCall(inputData);
        console.log(response,"response");
        return response;
    }

}

export default new HomeService()