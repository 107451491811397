import React, { Component } from 'react';
import ArrowLeft from "./../assets/images/al.png";
import HomeService from './../services/HomeService';
import { ShowPreloader, HidePreloader, ShowNotifications, GetLocalizationString } from './../Helpers/CustomHelper';
import Done from "./../assets/images/icon/done.png"

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      mobileNo: '',
      feedbackType: '1',
      description: '',
      errors: {},
      isSubmitted: false
    };
  }
componentDidMount() {
    ShowPreloader();
    HidePreloader();
}
  validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!this.state.firstName.trim()) {
      formIsValid = false;
      errors["firstName"] = "First name is required";
    }

    if (!this.state.mobileNo.trim()) {
      formIsValid = false;
      errors["mobileNo"] = "Mobile number is required";
    } else if (!/^\d{10}$/.test(this.state.mobileNo)) {
      formIsValid = false;
      errors["mobileNo"] = "Enter a valid 10-digit mobile number";
    }

    this.setState({ errors });
    return formIsValid;
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    ShowPreloader();

    if (this.validateForm()) {
      const orderPayload = {
        first_name: this.state.firstName,
        mobile_no: this.state.mobileNo,
        feedback_type: this.state.feedbackType,
        description: this.state.description
      };

      HomeService.PlaceFeedback(orderPayload)
        .then((res) => {
          HidePreloader();          
          if ((global.successStatus).includes(res.status)) {
            ShowNotifications('success', GetLocalizationString('feedback_msg'), 1500);
            this.setState({ isSubmitted: true });
            // this.props.history.push('/login');
          } else {
            ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
            this.setState({ isSubmitted: false });
          }
        })
        .catch(() => {
          HidePreloader();
          this.setState({ isSubmitted: false });
          ShowNotifications('error', GetLocalizationString('oops_something_wrong'), 1500);
        });
    } else {
      HidePreloader();
      this.setState({ isSubmitted: false });
      ShowNotifications('error', GetLocalizationString('validation_error'), 1500);
    }
  };

  render() {
    return (
      <>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-2">
                <a onClick={() => { this.props.history.goBack() }}><img src={ArrowLeft} alt="" /></a>
              </div>
              <div className="col-8">
                <h1 className="head_heading">{GetLocalizationString('contact_us')}</h1>
              </div>
            </div>
          </div>
        </header>
        {!this.state.isSubmitted ? (
        <section>
          <div className="container">
            <form onSubmit={this.handleSubmit} className="feedback-form">
              <div className="form-group">
                <label>Full Name*</label>
                <input 
                  type="text" 
                  name="firstName" 
                  className="form-control" 
                  value={this.state.firstName} 
                  onChange={this.handleChange} 
                  placeholder="Enter your full name"
                />
                <span className="error text-danger">{this.state.errors.firstName}</span>
              </div>
              <div className="form-group">
                <label>Phone Number*</label>
                <input 
                  type="text" 
                  name="mobileNo" 
                  className="form-control" 
                  value={this.state.mobileNo} 
                  onChange={this.handleChange} 
                  placeholder="Enter your phone number"
                />
                <span className="error text-danger">{this.state.errors.mobileNo}</span>
              </div>
              <div className="form-group">
                <label>Feedback Type*</label>
                <select name="feedbackType" className="form-control" value={this.state.feedbackType} onChange={this.handleChange}>
                  <option value="1">Feedback</option>
                  <option value="2">Delete Account</option>
                </select>
              </div>
              <div className="form-group">
                <label>Description (Optional)</label>
                <textarea 
                  name="description" 
                  className="form-control" 
                  value={this.state.description} 
                  onChange={this.handleChange} 
                  placeholder="Enter any remarks"
                ></textarea>
              </div>              
              {/* Place Order Button */}
              <div className="col-12 text-center mt-5">
                <button type="submit" className="btn btn-theme btn-book_suss">
                  {GetLocalizationString('submit')}
                </button>
              </div>
            </form>
          </div>
        </section>
        ) : (
        <section>
            <div className="container">
              <div className="row">
                <div className="col-12 ">
                  <div className="success_box ">
                    <div className="col-12 text-center">
                      <img src={Done} alt="" className="img-fluid"/>
                      <p className="text-sucess">{GetLocalizationString('feedback_msg')}</p>
                    </div>
        
                    {/* <Link onClick={()=>{
                        this.props.history.push({
                            pathname: '/home'
                        });
                    }} className="btn btn-theme mb-5">{GetLocalizationString('go_to_home')} <img src={ArrowRight} alt=""/></Link> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}

export default Feedback;
